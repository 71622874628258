import React from "react";
import Button from "react-bootstrap/Button";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo_.png";
import { useSelector } from "react-redux";

import HomeIconNew from "../assets/images/icons/building.png";
import CasinoIconNew from "../assets/images/icons/poker-cards.png";
import EzugiIconNew from "../assets/images/icons/roulette.png";
import EvolutionIconNew from "../assets/images/icons/playing-cards.png";
import SportsIconNew from "../assets/images/icons/football.png";
import WalletIconNew from "../assets/images/icons/digital-wallet.png";
import AviatorIconNew from "../assets/images/icons/airplane-mode.png";
import QtechIcon from "../assets/images/icons/qtech.png";
import SlotsIcon from "../assets/images/icons/slots.png";

const Header = () => {
  //   const [show, setShow] = useState(false);
  //   const handleClose = () => setShow(false);
  //   const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <header className="header">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <div className="logo">
                <a href="/">
                  <img src={appDetails?.LOGO_URL} alt="Logo" />
                </a>
              </div>
              <nav className="mainNav">
                <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
                  <li className="home">
                    <a className="active" href="/home">
                      <img src={HomeIconNew} alt="HomeIconNew" />
                      <span>Home</span>
                    </a>
                  </li>{" "}
                  <li className="home">
                    <a className="active" href="/sign-in">
                      <img src={SportsIconNew} alt="s" />
                      <span>Sports</span>
                    </a>
                  </li>
                  <li>
                    <a href="/games-all">
                      <img src={CasinoIconNew} alt="casino" />
                      <span>Casino</span>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi">
                      <img src={EzugiIconNew} alt="ezugi" />
                      <span>Ezugi</span>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/evolution">
                      <img src={EvolutionIconNew} alt="evolution" />
                      <span>Evolution</span>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/aviator">
                      <img src={AviatorIconNew} alt="aviator" />
                      <span>Aviator</span>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/qtech">
                      <img src={QtechIcon} alt="qtech" />
                      <span>Qtech</span>
                    </a>
                  </li>
                  <li>
                    <a href="/Slots">
                      <img src={SlotsIcon} alt="slots" />
                      <span>Slots</span>
                    </a>
                  </li>
                  <li>
                    <a href="/cashier">
                      <img src={WalletIconNew} alt="wallet" />
                      <span>Wallet</span>
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="headerRight">
                <ul>
                  <li>
                    <a
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                          : "javascript:void(0)"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      <WhatsappIcon />
                      <p>Support</p>
                    </a>
                  </li>
                </ul>
                <Button
                  variant="link"
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                  className="button-link"
                >
                  Log In
                </Button>
                {/* <Button
                  variant="primary"
                  onClick={() => {
                    navigate("/mobile-number");
                  }}
                  className="button-primary ms-2"
                >
                  Register
                </Button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
    </header>
  );
};

export default Header;
